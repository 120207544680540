import React from 'react';
import {Link} from "react-router-dom";

const Home = () => {
    return (
        <div>
            <nav>
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/about">About</Link></li>
                </ul>
            </nav>
            <h1>Welcome to My Home Page</h1>
            <p>
                This is the home page of my personal website. Here you can find the latest updates and interesting
                content.
            </p>
            <p>
                I'm excited to share my thoughts, experiences, and creations with you all. Hope you enjoy exploring!
            </p>
        </div>
    );
};

export default Home;