import React from 'react';
import {Link} from "react-router-dom";

const About = () => {
    return (
        <div>
            <nav>
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/about">About</Link></li>
                </ul>
            </nav>
            <h1>About Me</h1>
            <p>
                My name is [Your Name]. I'm passionate about [Your Interests/Hobbies]. I created this website to connect
                with others who share similar interests or just to share my journey in [relevant field or life in
                general].
            </p>
            <p>
                Over the years, I've gained experience in [mention some skills or experiences]. I love learning new
                things and constantly evolving, and this website is a part of that growth process.
            </p>
        </div>
    );
};

export default About;